<template>
    <div class="dashboard bookings sales analysis">
        <div class="head">
            <div class="title">Ventas Logradas</div>
        </div>

        <div class="summary" v-if="totalCost > 0">
            <div class="item">
                <div class="count">{{ $n(totalQty, 'decimal') }}</div>
                <div class="name">Cantidad</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(totalCost, 'currency') }}</div>
                <div class="name">Cost</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(totalMarkup, 'currency') }}</div>
                <div class="name">Markup</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(totalAgent, 'currency') }}</div>
                <div class="name">Agent</div>
            </div>
        </div>

        <div class="box-white filter">
            <vs-collapse>
                <vs-collapse-item icon-arrow="expand_more">
                    <div slot="header">
                        Buscador
                    </div>
                    <div class="wrapper">
                        <div class="item">
                            <div class="wrap">
                                <label for="">
                                    Fechas 
                                </label>

                                <vs-select  class="creSelect" v-model="dateFilter">
                                        <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in dateTypes" />
                                    </vs-select> 
                            </div>
                            <input-date-picker v-model="date" :currentDate="date"></input-date-picker>                
                        </div>
                        
                        <div class="item">
                            <label for="">Estado Reservación</label>
                            <multiselect v-model="stBookings" :options="bookStatus" :multiple="true" :max-height="160" placeholder="" 
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="DESCRIPTION" track-by="DESCRIPTION">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} estados seleccionados</span></template>
                            </multiselect>
                        </div>

                        <div class="item">
                            <label for="">Estado Servicios</label>
                            <multiselect v-model="services_status" :options="srvStatus" :multiple="true" :max-height="160" placeholder="" open-direction="top"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="NAME" track-by="NAME">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} estados servicios seleccionados</span></template>
                            </multiselect>
                        </div>

                        <div class="item">
                            <label for="">Tipo Servicios</label>
                            <multiselect v-model="services_types" :options="srvTypes" :multiple="true" :max-height="160" placeholder="" open-direction="top"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="NAME" track-by="NAME">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} estados servicios seleccionados</span></template>
                            </multiselect>
                        </div>

                        <div class="item">
                            <label for="">Categoría/Clasificación</label>
                            <multiselect v-model="classChoose" :options="classSrv" :multiple="true" :max-height="160" placeholder="" open-direction="top"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="DESCR" track-by="DESCR">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} categorias seleccionadas</span></template>
                            </multiselect>
                        </div>

                        <div class="item relative">
                            <div class='autocompleteel'> 
                                <div class="form-row">
                                    <vs-input type="text" label="Proveedor" v-model="searchTextSup" @keyup='loadSuggestions(1)'/>
                                    <div class='suggestionlist' v-if="suggestiondata.length && loadSuggestion == 1">
                                        <ul> 
                                            <li v-for='(item,index) in suggestiondata' @click='itemSelected(index, 1)' :key="index">  {{ item.NAME }} </li> 
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>

                        <div class="item relative">
                            <div class='autocompleteel'> 
                                <div class="form-row">
                                    <vs-input type="text" label="Agencia" v-model="searchText" @keyup='loadSuggestions(2)'/>
                                    <div class='suggestionlist' v-if="suggestiondata.length && loadSuggestion == 2">
                                        <ul> 
                                            <li v-for='(item,index) in suggestiondata' @click='itemSelected(index, 2)' :key="index">  {{ item.NAME }} </li> 
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>

                        <div class="item">
                            <vs-select label="Agrupar por"  v-model="groupBy">
                                <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in groupByData" />
                            </vs-select>
                        </div>
                        
                        <div class="item">
                            <vs-button color="primary"  @click="search()" type="filled" icon="search"></vs-button>
                            <vs-button color="warning"  @click="reset()" type="border" icon="backspace" 
                                v-if="date != ''  || agencies.length > 0 || stBookings.length > 0 || services_status.length > 0
                                    || services_types != '' || suppliers.length > 0 "></vs-button>
                        </div>
                    </div>
                    <div class="tags">
                        <div class="wrapper-tags">
                            <template v-for="(item, idx) in agencies">
                                <vs-chip :key="idx" transparent color="primary" closable @click="removeAgency(item, 2)">{{ item.NAME }}</vs-chip>
                            </template>
                            <template v-for="(item, idx) in suppliers">
                                <vs-chip :key="idx" transparent color="primary" closable @click="removeAgency(item, 1)">{{ item.NAME }}</vs-chip>
                            </template>
                        </div>
                    </div>
                </vs-collapse-item>
            </vs-collapse>
        </div>

        <div class="bookings-wrapper" v-if="status == 'done'">
            
            <table-sales :items="sales" v-if="mode == 'list'"></table-sales>
            
            <div v-if="mode == 'card'">
                <template v-for="(sale, idx) in sales">
                    <div :key="`card-${idx}`" class="card">
                        <div class="info">
                            <div class="item">
                                <span>Order:</span>
                                {{ idx + 1 }}
                            </div>
                            <div class="item">
                                <span>Code:</span>
                                {{ sale.code }}
                            </div>
                            <div class="item">
                                <span>Name:</span>
                                {{ sale.name }}
                            </div>
                            <div class="item">
                                <span>Qty Service:</span>
                                {{ $n(sale.items.length, 'decimal') }}
                                <span></span>
                                <span>Qty Days:</span>
                                {{ $n(sale.totalQty, 'decimal') }}
                            </div>
                            <div class="item cost">
                                <span>Cost:</span>
                                {{ $n(sale.totalCost, 'currency') }}
                            </div>
                            <div class="item cost">
                                <span>Markup:</span>
                                {{ $n(sale.totalMarkup, 'currency') }}
                            </div>
                            <div class="item cost">
                                <span>Agent:</span>
                                {{ $n(sale.totalAgent, 'currency') }}
                            </div>

                            <div class="item servicesList" @click="toggleRow(idx)">
                                Ver Listado de servicios <vs-icon icon="expand_more"></vs-icon>
                            </div>

                        </div>
                        <div class="wrapper" v-if="opened.includes(idx)">
                            <table-sales :items="sale.items"></table-sales>
                        </div>
                    </div>
                </template>
            
            </div>

            <pagination :total="pages" @handlePagination="handlePagination" :key="key" v-if="mode == 'list'"/>

        </div>

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import modal from "@/components/Modals/Basic.vue";
import pagination from '@/components/Pagination.vue'
import CatalogService from '@/services/CatalogService'
import BookingService from '@/services/BookingService'
import tableSales from '@/components/Tables/SalesAchivied.vue'
import inputDatePicker from '@/components/Inputs/input-date-range.vue'
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')

export default {
    name: "SalesAchiviedView",
    components: {
        pagination,
        modal,
        tableSales,
        inputDatePicker
    },
    data () {
        return {
            bkAnalysis: [],
            key: 1,
            totalPost: 26,
            date: "",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            status: "",
            disabled: false,
            stBookings: [],
            bookStatus: [],
            types: [],
            agencies: [],
            searchText: "",
            suggestiondata:[],
            srvStatus: [],
            srvTypes: [],
            services_status: [],
            services_types: [],
            groupBy: "NA",
            groupByData: [
                {
                    code: "NA",
                    name: 'Sin agrupar'
                },
                {
                    code: "SP",
                    name: 'Por Proveedor'
                },
                {
                    code: "AG",
                    name: 'Por Agencia'
                }
            ],
            suppliers:[],
            loadSuggestion: 1,
            searchTextSup: "",
            sales: [],
            totalCost: 0,
            totalMarkup: 0, 
            totalAgent: 0, 
            totalQty: 0,   
            totalReg: 0,
            paged: 1,
            pages: 0,
            mode: 'list',
            classSrv: [],
            classChoose: [],
            dateFilter: "TravelDate",
            dateTypes: [{ code: "TravelDate", name:"Travel Date"}, {code: "SellDate", name: "Sell Date"}],
            opened: [],
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        this.setDefaultValues(); 
        await this.getSalesAchivied();
        await this.getBookingStatus();
        await this.getServiceStatus();
        await this.getServiceTypes();
        await this.getServiceClass();
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async getSalesAchivied(){
            this.LOADING_STATUS(true);
            
            let response = await BookingService.getSalesAchivied({
                "begDate" : this.date != '' ? new Date(this.date.start) : "", //opcional
                "endDate" : this.date != '' ? new Date(this.date.end) : "",//opcional
                "agencies" :    this.agencies, //opcional
                "booking_status": this.stBookings,
                "suppliers": this.suppliers,
                "services_status": this.services_status,
                "services_types": this.services_types,
                "pages" : this.pages,
                "paged" : this.paged,
                "groupBy": this.groupBy,
                "classChoose": this.classChoose,
                "dateFilter": this.dateFilter,
            });            
            if((response.status/100) == 2){
                this.sales = this.groupBy != 'NA' ? response.data.groupData : response.data.items
                this.totalCost   = response.data.totalCost;
                this.totalMarkup = response.data.totalMarkup;
                this.totalAgent  = response.data.totalAgent;
                this.totalQty    = response.data.totalQty;
                this.totalReg = response.data.total;
                this.pages    = response.data.pages;
                this.mode = this.groupBy != 'NA' ? 'card' : 'list'
                this.key++
            } 

            this.LOADING_STATUS(false);
        },
        
        async getBookingStatus(){
            this.LOADING_STATUS(true);
            this.bookStatus = await CatalogService.getBookingStatus({});            
            this.LOADING_STATUS(false);
        },
        async getServiceStatus(){
            this.LOADING_STATUS(true);
            this.srvStatus = await CatalogService.getServiceStatus({});            
            this.LOADING_STATUS(false);
        },
        async getServiceTypes(){
            this.LOADING_STATUS(true);
            this.srvTypes = await CatalogService.getServiceTypes({});            
            this.LOADING_STATUS(false);
        },
        async getServiceClass(){
            this.LOADING_STATUS(true);
            this.classSrv = await CatalogService.getServiceClass({});            
            this.LOADING_STATUS(false);
        },
        async getConsultants(){
            this.LOADING_STATUS(true);
            this.consultants = await CatalogService.getConsultants({});            
            this.LOADING_STATUS(false);
        },        
        handlePagination(_paged) {            
            this.paged = _paged    
            this.getSalesAchivied()
        },
        search() {
            this.totalReg = 0;
            this.paged = 1;
            this.pages = 0;
            this.getSalesAchivied()
        },
        reset() {
            this.date = "";
            this.agencies  = [];
            this.stBookings = [];
            this.suppliers  = [];
            this.services_types = "";
            this.setDefaultValues();
            this.getSalesAchivied();
        },
        setDefaultValues(){
            this.stBookings = [ 
                { CODE: 'OK', DESCRIPTION: "Confirmed" },
                { CODE: 'IN', DESCRIPTION: "Invoiced" }, 
                { CODE: 'RE', DESCRIPTION: "Reservation"}, 
                { CODE: 'PP', DESCRIPTION: "Partial Pay"}, 
                { CODE: 'PA', DESCRIPTION: "Paid"}
            ];
            this.services_status = [
                // { CODE: 'PE', NAME: "Pending" }, 
                // { CODE: 'RQ', NAME: "Request"} ,
                { CODE: 'OK', NAME: "Confirmed"},
                { CODE: 'CO', NAME: "Reconfirmed"}, 
                { CODE: 'CD', NAME: "Confirmed / Differen"}]
        },
        async loadSuggestions(type){
            this.suggestiondata = [];
            this.loadSuggestion = type;
            if(this.searchText != '' || this.searchTextSup != ''){
                let response = type == 1 ? await CatalogService.getSuppliers({ search: this.searchTextSup}) : await CatalogService.getAgencies({ search: this.searchText})
                console.log('response', response)
                if((response.status/100) == 2){
                    this.suggestiondata = response.data
                    // this.searchText = ''
                }
            }
        },
        itemSelected(index, type){
            if(type == 2){
                if(this.agencies.findIndex(item => item.CODE == this.suggestiondata[index].CODE) < 0){
                    this.agencies.push(this.suggestiondata[index])
                }
            } else {
                if(this.suppliers.findIndex(item => item.CODE == this.suggestiondata[index].CODE) < 0){
                    this.suppliers.push(this.suggestiondata[index])
                }
            }
            this.suggestiondata = [];
            this.searchText = ''
            this.searchTextSup = ''
        },
        removeAgency (item, type) {
            if(type == 1){
                this.suppliers.splice(this.suppliers.indexOf(item), 1)
            }else{
                this.agencies.splice(this.agencies.indexOf(item), 1)
            }
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        },
    }
}
</script>